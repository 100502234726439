import {useState} from "react";
import {BooleanField, EditButton, Show, SimpleShowLayout, TextField, TopToolbar} from "react-admin";
import * as React from "react";

export const TagShow = (props) => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    return (
        <Show {...props} actions={<TagShowActions/>} title={"Catégorie"}>
            <SimpleShowLayout>
                <h2 fullWidth>Tag</h2>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language}>
                            <TextField
                                style={{display: "none"}}
                                initialvalue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <span style={{fontWeight: 'bold', fontSize: 14}}>Nom : </span>
                            <TextField
                                label={"Nom"}
                                source={"content[" + idx + "].name"}
                            />
                            <br/>
                        </div>
                    )
                }
                <BooleanField
                    label={"Public"}
                    source="publicTag"
                />
            </SimpleShowLayout>
        </Show>
    )
};

const TagShowActions = (
    {
        basepath, data, resource
    }
) => (
    <TopToolbar>
        <EditButton basepath={basepath} record={data}/>
    </TopToolbar>
);
