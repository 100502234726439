import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    SelectInput,
    TextInput,
    Filter,
    FunctionField,
    DateField, SimpleList, Confirm, useRefresh
} from 'react-admin';
import {Constants} from "../../utils/Constants";
import {formatPrice, scrollToBottom} from "../../utils/Utils";
import {useMediaQuery} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ReceiptIcon from "@material-ui/icons/Receipt";
import axios from "axios";
import {useState} from "react";
import {Loader} from "../Loader";

const OrderFilter = (props) => (
    <Filter {...props}>
        <TextInput
            label="Numéro de commande"
            source="id"
            type="number"
            min={0}
            alwaysOn/>
        <TextInput label="Email" source="email" alwaysOn/>
        <SelectInput
            label="Statut"
            source="status"
            choices={Object.entries(Constants.orderStatus).map(status => {
                return {
                    id: status[0],
                    name: status[1]
                }
            })}
            alwaysOn
        />
    </Filter>
);


export const OrderList = (props) => {

        const [isDocumentLoading, setDocumentLoading] = useState(false);
        const [orderIdPopup, setOrderIdPopup] = useState();
        const [popupTitle, setPopuptitle] = useState("");
        const [currentType, setCurrentType] = useState();
        const refresh = useRefresh();

        const onClickDocIcon = (record, type) => {
            //Code pas ouf du tout
            setOrderIdPopup(record.orderId);
            if (type === "purchase-order" && record.purchaseOrderPath === null) {
                setCurrentType(type);
                setPopuptitle("Créer le bon de commande ?");
            }
            if (type === "purchase-order" && record.purchaseOrderPath !== null) {
                openInNewTab(record.purchaseOrderPath);
            }
            if (type === "delivery-note" && record.deliveryNotePath === null) {
                setCurrentType(type);
                setPopuptitle("Créer le bon de livraison ?");
            }
            if (type === "delivery-note" && record.deliveryNotePath !== null) {
                openInNewTab(record.deliveryNotePath);
            }
            if (type === "bill" && record.billPath === null) {
                setCurrentType(type);
                setPopuptitle("Créer la facture ?");
            }
            if (type === "bill" && record.billPath !== null) {
                openInNewTab(record.billPath);
            }
        }

        const generateBill = (orderId) => {
            const jwt = localStorage.getItem('auth');
            setDocumentLoading(true);
            setCurrentType(undefined);
            axios({
                method: 'put',
                url: Constants.baseApiUrl + Constants.generateBill.replace("%ID%", orderId),
                headers: {'Authorization': 'Bearer ' + jwt}
            }).then(response => {
                setDocumentLoading(false);
                refresh();
            }).catch(error => {
                setDocumentLoading(false);
                alert("Erreur interne " + error);
            });
        }

        const generateDeliveryNote = (orderId) => {
            const jwt = localStorage.getItem('auth');
            setDocumentLoading(true);
            setCurrentType(undefined);
            axios({
                method: 'put',
                url: Constants.baseApiUrl + Constants.generateDeliveryNote.replace("%ID%", orderId),
                headers: {'Authorization': 'Bearer ' + jwt}
            }).then(response => {
                setDocumentLoading(false);
                refresh();
            }).catch(error => {
                setDocumentLoading(false);
                alert("Erreur interne " + error);
            });
        }

        const generatePurchaseOrder = (orderId) => {
            const jwt = localStorage.getItem('auth');
            setDocumentLoading(true);
            setCurrentType(undefined);
            axios({
                method: 'put',
                url: Constants.baseApiUrl + Constants.generatePurchaseOrder.replace("%ID%", orderId),
                headers: {'Authorization': 'Bearer ' + jwt}
            }).then(response => {
                setDocumentLoading(false);
                refresh();
            }).catch(error => {
                setDocumentLoading(false);
                alert("Erreur interne " + error);
            });
        }

        const openInNewTab = (docPath) => {
            window.open(Constants.objectStoragePath + docPath, '_blank').focus();
        }

        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        return (
            <List
                {...props}
                title={"Liste des commandes"}
                filters={<OrderFilter/>}
                exporter={false}
                bulkActionButtons={false}
                sort={{field: 'id', order: 'DESC'}}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => `Commande n°${record.id}`}
                        secondaryText={record => <span>
                            <DateField showTime source={"date"} record={{date: record.orderDate}}/><br/>
                            {record.email}<br/>
                            {record.phone}</span>}
                        tertiaryText={record => <span
                            style={Constants.orderStyle[record.status]}>{Constants.orderStatus[record.status]}</span>}
                        linkType={"show"}
                    />
                ) : (
                    <Datagrid>
                        <TextField
                            label={"N°"}
                            source="id"
                        />
                        <DateField
                            showTime
                            label={"Date de création"}
                            source="orderDate"
                        />
                        <FunctionField
                            label={"Email"}
                            source={"email"}
                            render={record => {
                                return <a href={"mailto:" + record.email}>{record.email}</a>;
                            }}
                        />
                        {/*<FunctionField*/}
                        {/*    label={"Téléphone"}*/}
                        {/*    source={"phone"}*/}
                        {/*    render={record => {*/}
                        {/*        return <a href={"tel:" + record.phone}>{record.phone}</a>;*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <FunctionField
                            label={"Statut"}
                            source={"status"}
                            render={record => {
                                return <span
                                    style={Constants.orderStyle[record.status]}>{Constants.orderStatus[record.status]}</span>;
                            }}
                        />
                        <FunctionField
                            label={"Prix TTC"}
                            sortable={false}
                            render={record => {
                                if (record.cart?.totals?.totalWithTax !== undefined) {
                                    return formatPrice(record.cart.totals.totalWithTax, record.cart.currency);
                                }
                            }}
                        />
                        <FunctionField
                            label={"Nb art."}
                            sortable={false}
                            render={record => {
                                if (record.cart?.products !== undefined) {
                                    return record.cart?.products.reduce((acc, current) => acc + current.quantity, 0);
                                }
                            }}
                        />
                        <TextField
                            label={"Livraison"}
                            source="delivery.name"
                            sortable={false}
                        />
                        <FunctionField
                            label={"Documents"}
                            sortable={false}
                            render={record => {
                                if (isDocumentLoading && record.orderId === orderIdPopup) {
                                    return <Loader/>
                                }

                                return (
                                    <>
                                        <span
                                            style={{cursor: "pointer", margin: 5}}
                                            onClick={() => onClickDocIcon(record, "purchase-order")}
                                        >
                                            <ShoppingCartIcon
                                                color={record.purchaseOrderPath === null ? "action" : "primary"}
                                            />
                                        </span>
                                        <span
                                            style={{cursor: "pointer", margin: 5}}
                                            onClick={() => onClickDocIcon(record, "delivery-note")}
                                        >
                                            <LocalShippingIcon
                                                color={record.deliveryNotePath === null ? "action" : "primary"}
                                            />
                                        </span>
                                        <span
                                            style={{cursor: "pointer", margin: 5}}
                                            onClick={() => onClickDocIcon(record, "bill")}
                                        >
                                            <ReceiptIcon
                                                color={record.billPath === null ? "action" : "primary"}
                                            />
                                        </span>
                                        <Confirm
                                            isOpen={record.orderId === orderIdPopup && currentType !== undefined}
                                            title={popupTitle}
                                            content={"La génération du document ne pourra pas être annulée"}
                                            onConfirm={() => {
                                                if (currentType === "purchase-order") {
                                                    generatePurchaseOrder(record.orderId)
                                                }
                                                if (currentType === "delivery-note") {
                                                    generateDeliveryNote(record.orderId)
                                                }
                                                if (currentType === "bill") {
                                                    generateBill(record.orderId)
                                                }
                                            }}
                                            onClose={() => setCurrentType(undefined)}
                                            confirm={"Confirmer"}
                                            cancel={"Annuler"}
                                        />
                                    </>
                                )
                                if (record.cart?.products !== undefined) {
                                    return record.cart?.products.reduce((acc, current) => acc + current.quantity, 0);
                                }
                            }}
                        />
                        <ShowButton basepath="/orders" record={props.record}/>
                        {/*<EditButton basepath="/orders"/>*/}
                    </Datagrid>
                )}

            </List>
        )
    }
;
