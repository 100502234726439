import {useMediaQuery} from "@material-ui/core";
import {
    Datagrid,
    EditButton,
    FunctionField,
    List,
    ShowButton,
    SimpleList,
    TextField,
    ReferenceField, Filter, TextInput
} from "react-admin";
import {ImageField} from "../ImageField";
import * as React from "react";

const RangeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Code de la gamme" source="code" alwaysOn/>
    </Filter>
);

export const RangeList = (props) => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        return (
            <List
                {...props}
                title="Liste des gammes"
                filters={<RangeFilter/>}
                exporter={false}
                bulkActionButtons={false}
            >
                {isSmall ? (
                    <SimpleList
                        // TODO use default language
                        primaryText={record => record.content.filter(range => range.language === "FR")[0].name}
                        secondaryText={record => <div
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}
                        >
                            <ImageField
                                path={record.imagePath}
                                alt={record.code}
                                height={100}
                            />
                        </div>}
                        tertiaryText={record => record.code}
                        linkType={"show"}
                    />
                ) : (
                    <Datagrid>
                        <TextField source="id"/>

                        <ReferenceField
                            label="Catégorie"
                            source="categoryId"
                            reference="categories"
                            link={false}
                        >
                            {/*//TODO default language*/}
                            <TextField source="content[0].name"/>
                        </ReferenceField>
                        <TextField source="code"/>
                        <FunctionField
                            source="name"
                            label={"Nom"}
                            sortable={false}
                            // TODO use default language
                            render={record => record.content.filter(range => range.language === "FR")[0].name}
                        />
                        <FunctionField
                            render={record => <ImageField
                                path={record.imagePath}
                                alt={record.code}
                                height={100}
                            />}
                            label={"Image"}/>
                        <TextField
                            sortable={false}
                            source="nbItems"
                            label={"Nombre de produits"}
                        />
                        <ShowButton basepath="/ranges" record={props.record}/>
                        <EditButton basepath="/ranges"/>
                    </Datagrid>
                )}
            </List>
        )
    }
;
