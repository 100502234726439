import * as React from "react";
import {formatPrice} from "../../utils/Utils";

export const Price = (props) => (
    <>
        {
            props.originalAmountWithTax !== null && <div style={{textDecoration: "line-through"}}>
                {formatPrice(props.originalAmountWithTax, props.currency)}
            </div>
        }
        <div>{formatPrice(props.amountWithTax, props.currency)}</div>
    </>
);

export const PriceMob = (props) => (
    <>
        {
            props.originalAmountWithTax !== null && <span style={{textDecoration: "line-through"}}>
                {formatPrice(props.originalAmountWithTax, props.currency)}
            </span>
        }
        <span>{formatPrice(props.amountWithTax, props.currency)}</span>
    </>
);

export const PriceBO = (props) => {
    if (props.discountAmountWithTax !== null) {
        return (
            <>
                <div style={{textDecoration: "line-through"}}>
                    {formatPrice(props.amountWithTax, props.currency)}
                </div>
                <div>{formatPrice(props.discountAmountWithTax, props.currency)}</div>
                <br/>
            </>
        )
    } else {
        return (
            <>
                <div>{formatPrice(props.amountWithTax, props.currency)}</div>
                <br/>
            </>
        )
    }
};

export const PriceTotal = (props) => {
    return (
        <>
            <div>
                Total HT : {formatPrice(props.totalWithoutTax, props.currency)}
            </div>
            <div>
                TVA : {formatPrice(props.totalTax, props.currency)}
            </div>
            <div>
                Total TTC : {formatPrice(props.totalWithTax, props.currency)}
            </div>
            {/*<div>{formatPrice(props.discountAmountWithTax, props.currency)}</div>*/}
            <br/>
        </>
    )
};