import {DateField, EditButton, NumberField, Show, SimpleShowLayout, TextField, TopToolbar} from "react-admin";
import * as React from "react";

export const ReviewShow = (props) => {

    return (
        <Show {...props} actions={<ReviewShowActions/>} title={"Avis"}>
            <SimpleShowLayout>
                <h2>Avis client</h2>
                <TextField
                    source="productName"
                    label={"Produit"}
                />
                <TextField
                    source="orderId"
                    label={"N° Commande"}
                />
                <TextField
                    source="author"
                    label={"Auteur"}
                />
                <DateField
                    showTime
                    label={"Date"}
                    source={"reviewDate"}
                />
                <NumberField
                    label={"Score"}
                    source="score"
                />
                <TextField
                    source="review"
                    label={"Avis client"}
                />
                <DateField
                    showTime
                    source="responseDate"
                    label={"Date de la réponse"}
                />
                <TextField
                    source="response"
                    label={"Réponse"}
                />
            </SimpleShowLayout>
        </Show>
    )
}

const ReviewShowActions = (
    {
        basepath, data, resource
    }
) => (
    <TopToolbar>
        <EditButton basepath={basepath} record={data}/>
    </TopToolbar>
);
