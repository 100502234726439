const mainOrderStyle = {
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "5px",
    borderRadius: 99
}

export const Constants = {
    env: process.env.REACT_APP_ENV,
    baseApiUrl: process.env.REACT_APP_API_URL,
    managerPrefix: "manage",
    uploadProductImage: "/products/image",
    uploadCategoryImage: "/category/image",
    uploadRangeImage: "/range/image",
    categories: "/min/categories",
    ranges: "/ranges",
    generateBill: "manage/orders/%ID%/bill",
    generateColissimoLabel: "manage/orders/%ID%/colissimo",
    generateDeliveryNote: "manage/orders/%ID%/deliverynote",
    generatePurchaseOrder: "manage/orders/%ID%/purchaseorder",
    generateCredit: "manage/orders/%ID%/credit",
    objectStoragePath: process.env.REACT_APP_OBJECT_STORAGE_PATH,
    mondialRelayType: "MONDIALRELAY",
    orderStatus: {
        PAID: "Payée",
        WAITING: "En attente",
        PAYMENTWAITING: "Paiement en attente",
        CANCELED: "Annulée",
        SENDED: "Expédiée",
        AVAILABLE: "Disponible au retrait",
        WITHDRAWN: "Retirée",
        REFUNDED: "Remboursée"
    },
    orderStyle: {
        PAID: {
            ...mainOrderStyle,
            backgroundColor: "#1fb100",
            color: "white",
        },
        WAITING: {
            ...mainOrderStyle,
            backgroundColor: "#ffff79",
            color: "black",
        },
        PAYMENTWAITING: {
            ...mainOrderStyle,
            backgroundColor: "#b0b050",
            color: "black",
        },
        CANCELED: {
            ...mainOrderStyle,
            backgroundColor: "#d41717",
            color: "white",
        },
        SENDED: {
            ...mainOrderStyle,
            backgroundColor: "#00e6ff",
            color: "black",
        },
        AVAILABLE: {
            ...mainOrderStyle,
            backgroundColor: "#88459c",
            color: "white",
        },
        WITHDRAWN: {
            ...mainOrderStyle,
            backgroundColor: "#1c7600",
            color: "white",
        },
        REFUNDED: {
            ...mainOrderStyle,
            backgroundColor: "#760000",
            color: "white",
        }
    },
    orderAvailableStatus: {
        CLICKANDCOLLECT: [
            "PAYMENTWAITING",
            "PAID",
            "AVAILABLE",
            "WITHDRAWN",
            "CANCELED"
        ],
        OTHERS: [
            "PAYMENTWAITING",
            "PAID",
            "SENDED",
            "CANCELED"
        ]
    },
    pspName: {
        STRIPECB: "Carte bancaire (via Stripe)",
        STRIPEGOOGLEPAY: "Google Pay (via Stripe)",
        STRIPEAPPLEPAY: "Apple Pay (via Stripe)",
        POSTFINANCETWINT: "Twint (via PostFinance)",
        POSTFINANCEEFINANCE: "PostFinance e-finance (via PostFinance)",
        POSTFINANCECARD: "Carte bancaire (via PostFinance)",
        CHECK: "Chèque",
        TRANSFER: "Virement",
        PAYPAL: "Paypal",
    },
    transactionStatus: {
        SUCCESS: "Succès",
        WAITING: "En attente",
        CANCELED: "Annulée",
        REFUSED: "Refusée"
    }
}
