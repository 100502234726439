import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    BooleanField,
    FunctionField,
    SimpleList,
    Filter,
    TextInput, downloadCSV
} from 'react-admin';
import {Price, PriceMob} from "./Price";
import {ImageField} from "../ImageField";
import {useMediaQuery} from "@material-ui/core";
import jsonExport from 'jsonexport/dist';

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom du produit" source="name" alwaysOn/>
    </Filter>
);

const exporter = products => {
    const productsForExport = products.map(product => {
        const {description, ...productForExport} = product; // omit fields
        return productForExport;
    });
    jsonExport(productsForExport, {
    }, (err, csv) => {
        downloadCSV(csv, 'products');
    });
};

export const ProductList = (props) => {
        const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

        return (
            <List
                {...props}
                title="Liste des produits"
                filters={<ProductFilter/>}
                exporter={exporter}
                bulkActionButtons={false}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.name}
                        secondaryText={record => <div
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}
                        >
                            <ImageField
                                path={record.mainImage}
                                alt={record.name}
                                height={100}
                            />
                        </div>}
                        tertiaryText={record =>
                            <span>{record.published ? "En ligne" : "Hors ligne"} - <PriceMob {...record}/></span>}
                        linkType={"show"}
                    />
                ) : (
                    <Datagrid>
                        <TextField source="id"/>
                        <FunctionField
                            render={record => <ImageField
                                path={record.mainImage}
                                alt={record.name}
                                height={100}
                            />}
                            label={"Image principale"}/>
                        <TextField source="name" label={"Nom"}/>
                        <BooleanField source="published" label={"En ligne"}/>
                        <FunctionField
                            render={record => {
                                if (record.quantityInStock !== null) {
                                    return record.quantityInStock;
                                } else {
                                    return "Illimité";
                                }
                            }}
                            label={"Stock"}
                            sortBy={"quantityInStock"}
                        />
                        <FunctionField
                            render={record => {
                                if (record.productRangeCode !== null) {
                                    return <span
                                        style={{
                                            backgroundColor: "#c2c2c2",
                                            padding: 8,
                                            borderRadius: 99
                                        }}>
                                    {record.productRangeCode}
                                </span>;
                                }
                            }}
                            label={"Gamme"}
                            sortBy={"productRangeCode"}
                        />
                        <FunctionField render={Price} label={"Prix TTC"} sortBy={"amountWithTax"}/>
                        <ShowButton basepath="/products" record={props.record}/>
                        <EditButton basepath="/products"/>
                    </Datagrid>
                )}
            </List>
        )
    }
;
