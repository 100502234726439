import * as React from "react";
import Typography from "@material-ui/core/Typography";
import {IconButton, Tooltip} from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import {withStyles} from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f1f1f1',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export const CustomTooltip = (props) => (
    <HtmlTooltip
        placement="right"
        title={
            <React.Fragment>
                <Typography color="inherit">{props.title}</Typography>
                {props.body}
            </React.Fragment>
        }
    >
        <IconButton aria-label="info">
            <Info/>
        </IconButton>
    </HtmlTooltip>
);