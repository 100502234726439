import * as React from "react";
import {useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {ImageCrop} from "./ImageCrop";

export const PictureUpload = (props) => {

    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null);

    const onDrop = (files) => {
        if (files !== undefined && files.length > 0) {
            setFile(files[0]);
            setStep(2);
        }
    }

    const cancelImage = () => {
        setFile(null);
        setStep(1);
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        multiple: false,
        onDrop: onDrop
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    if (step === 1) {
        return (
            <div className="container">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    <p>Glissez une image ou cliquez pour sélectionner</p>
                </div>
            </div>
        );
    }
    if (step === 2) {
        return (
            <ImageCrop
                type={props.type ? props.type : "product"}
                file={file}
                onConfirm={props.onNewPictureUpload}
                cancel={cancelImage}
            />
        );
    }
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};