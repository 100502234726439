import {useMediaQuery} from "@material-ui/core";
import {
    Datagrid,
    EditButton,
    FunctionField,
    List,
    ShowButton,
    SimpleList,
    TextField
} from "react-admin";
import {ImageField} from "../ImageField";
import * as React from "react";

export const CategoryList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List {...props} title="Liste des categories" exporter={false} bulkActionButtons={false}>
            {isSmall ? (
                <SimpleList
                    // TODO use default language
                    primaryText={record => record.content.filter(cat => cat.language === "FR")[0].name}
                    secondaryText={record => <div
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10}}
                    >
                        <ImageField
                            path={record.coverImage}
                            alt={record.name}
                            height={100}
                            width={380}
                        />
                    </div>}
                    // TODO use default language
                    tertiaryText={record => record.content.filter(cat => cat.language === "FR")[0].subtitle}
                    linkType={"show"}
                />
            ) : (
                <Datagrid>
                    <TextField
                        source="id"
                        label={"Categorie ID"}
                    />
                    <FunctionField
                        source="name"
                        label={"Nom"}
                        sortable={false}
                        // TODO use default language
                        render={record => record.content.filter(cat => cat.language === "FR")[0].name}
                    />
                    <FunctionField
                        source="subtitle"
                        label={"Sous-titre"}
                        sortable={false}
                        // TODO use default language
                        render={record => record.content.filter(cat => cat.language === "FR")[0].subtitle}
                    />
                    <FunctionField
                        render={record => <ImageField
                            path={record.coverImage}
                            alt={"Category"}
                            height={100}
                            width={380}
                        />}
                        label={"Couverture"}/>
                    <ShowButton basepath="/categories" record={props.record}/>
                    <EditButton basepath="/categories"/>
                </Datagrid>
            )}
        </List>
    )
}