import * as React from "react";

export const Loader = () => (
    <div className="lds-ring">
        <div/>
        <div/>
        <div/>
        <div/>
    </div>
);
