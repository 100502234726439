import * as React from "react";
import {
    FunctionField,
    Edit, TextInput, SimpleForm, Toolbar, SaveButton
} from 'react-admin';
import {languages} from "../../utils/Utils";
import {useState} from "react";

const OtherEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);
export const OtherEdit = (props) => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    return (
        <Edit {...props} toolbar={null}>
            <SimpleForm toolbar={<OtherEditToolbar/>}>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language}>
                            <FunctionField
                                label={""}
                                source={"content[" + idx + "].language"}
                                render={
                                    record => {
                                        if (record.content === null || record.content === undefined || record.content.length <= idx || record.content[idx].description === undefined) {
                                            return "";
                                        }
                                        return <h3>{languages[record.content[idx].language]}</h3>
                                    }
                                }
                            />
                            <TextInput
                                style={{display: "none"}}
                                initialValue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <span style={{fontWeight: 'bold', fontSize: 14}}>Bandeau {language} : </span>
                            <TextInput
                                style={{width: '100%'}}
                                label={"Texte"}
                                source={"content[" + idx + "].value"}
                            />
                        </div>
                    )
                }
            </SimpleForm>
        </Edit>
    )
};
