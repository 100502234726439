import {useRef, useState} from "react";
import {
    Create, FunctionField, ReferenceInput,
    required, SelectInput,
    SimpleForm,
    TextInput, Toolbar
} from "react-admin";
import {languages} from "../../utils/Utils";
import {CustomTooltip} from "../CustomTooltip";
import * as React from "react";
import {PictureUpload} from "../PictureUpload";
import {ImageField} from "../ImageField";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

export const RangeCreate = props => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));
    const [picture, setPicture] = useState(undefined);
    const [initialLoadFinished, setInitialLoadFinished] = useState(false);
    const stateRef = useRef();
    stateRef.current = picture;

    const transform = (values) => {
        const obj = {...values};
        obj.imagePath = stateRef.current;
        return obj;
    }

    const uploadPictureCallback = (obj) => {
        setPicture(obj);
    }

    const deleteImage = () => {
        setPicture(null);
    }

    return (
        <Create title="Ajout d'une gamme"  {...props} transform={transform}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={"show"}>
                <div style={{width: "100%"}}>
                    <TextInput source={'code'} label={"Code de la gamme"}/>
                    <CustomTooltip
                        title={"Code de la gamme"}
                        body={"Identifiant interne, il sera affiché lors de la création ou l'édition d'un produit. "}
                    />
                </div>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language} style={{width: "100%"}}>
                            <div>
                                <FunctionField
                                    style={{display: 'inline-block'}}
                                    label={""}
                                    source={"content[" + idx + "].language"}
                                    render={
                                        record => {
                                            return <h3>{languages[language]}</h3>
                                        }
                                    }
                                />
                                <CustomTooltip
                                    title={"Traductions"}
                                    body={"Le nom de la gamme varie en fonction des langues. " +
                                    "Si plusieurs langues sont activées sur votre boutique vous devez saisir les données pour chacune d'elle."}
                                />
                            </div>
                            <TextInput
                                style={{display: "none"}}
                                initialValue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <TextInput
                                label={"Nom"}
                                source={"content[" + idx + "].name"}
                                validate={[required()]}
                            />
                        </div>
                    )
                }
                <br/>
                <ReferenceInput
                    label="Catégorie"
                    source="categoryId"
                    reference="categories"
                    validate={[required()]}
                >
                    {/* TODO use default language*/}
                    <SelectInput
                        optionText={record => record.content.filter(cat => cat.language === "FR")[0].name}
                    />
                </ReferenceInput>
                <FunctionField
                    label={""}
                    source={"empty"}
                    render={record => {
                        if (!initialLoadFinished && picture === undefined) {
                            //first render
                            //Désolé pour le bricolage
                            setTimeout(() => {
                                setPicture(record.imagePath);
                                setInitialLoadFinished(true);
                            }, 500);
                        }
                    }}
                />
                {
                    picture === undefined ? (
                        <>
                            <h2 fullWidth>Ajoutez l'image</h2>
                            <PictureUpload
                                type={"range"}
                                {...props}
                                onNewPictureUpload={uploadPictureCallback}
                            />
                            <TextInput
                                style={{display: "none"}}
                                source={"emptyImage"}
                                validate={[required()]}
                            />
                        </>
                    ) : (
                        <div style={{position: 'relative'}}>
                            <h2 fullWidth>Image</h2>
                            <div style={{position: 'relative'}}>

                                <ImageField
                                    path={picture}
                                    alt={"Range image"}
                                    height={200}
                                />
                                <span
                                    style={deleteIcon}
                                    onClick={() => {
                                        if (window.confirm("Supprimer l'image ?")) {
                                            deleteImage();
                                        }
                                    }}
                                >
                                <DeleteIcon fontSize={"small"}/>
                            </span>
                            </div>
                        </div>
                    )
                }
            </SimpleForm>
        </Create>

    )
}

const deleteIcon = {
    position: "absolute",
    top: 0,
    left: 160,
    zIndex: 10,
    backgroundColor: "white",
    margin: 5,
    padding: 5,
    borderRadius: 999,
    width: 20,
    height: 20,
};