import * as React from "react";
import {
    List, Datagrid, TextField, ShowButton, EditButton
} from 'react-admin';

export const OtherList = (props) => {

    return (
        <List
            {...props}
            title="Autre"
            bulkActionButtons={false}
            exporter={false}
        >
            <Datagrid>
                <TextField source={"name"} label={"Nom"}/>
                <TextField source={"description"} label={"Détails"}/>
                <ShowButton basepath="/other" record={props.record}/>
                <EditButton basepath="/other"/>
            </Datagrid>
        </List>
    )
};
