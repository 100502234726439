import * as React from "react";
import {
    Edit,
    TextInput,
    TabbedForm,
    FormTab,
    SelectInput,
    required,
    NumberInput,
    BooleanInput,
    minValue,
    number,
    Toolbar,
    FunctionField, ReferenceInput, AutocompleteArrayInput
} from 'react-admin';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import RichTextInput from 'ra-input-rich-text';
import {currencies, defaultToolBar, getCategories, getInputField, languages, vatRates} from "../../utils/Utils";
import {useEffect, useRef, useState} from "react";
import {CustomTooltip} from "../CustomTooltip";
import {PictureUpload} from "../PictureUpload";
import {ImageField} from "../ImageField";

export const ProductEdit = props => {

    const [categories, setCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [pictures, setPictures] = useState([]);
    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));
    const [customFields] = useState(JSON.parse(localStorage.getItem('customProductFields')));
    const [initialLoadFinished, setInitialLoadFinished] = useState(false);
    const stateRef = useRef();

    stateRef.current = pictures;

    useEffect(() => {
        getCategories()
            .then(response => {
                setCategories(response.data.map(cat => {
                    return {id: cat.id, name: cat.name}
                }));
            })
            .catch(err => {
                //TODO toast
                alert("erreur inattendue");
            })
    }, []);

    const addNewPicture = (obj) => {
        setPictures([...pictures, obj]);
    }

    const deleteImage = (idx) => {
        const newPictures = [...pictures];
        newPictures.splice(idx, 1);
        setPictures(newPictures);
    }

    const transform = (values) => {
        values.pictures = stateRef.current;

        if (typeof values.productRangeId === "string") {
            values.newProductRange = values.productRangeId;
            delete values.productRangeId;
        }

        return values;
    }

    return (
        <Edit {...props} transform={transform}>
            <TabbedForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={"show"}>
                <FormTab label={"Principal"}>
                    <h2 fullWidth>Informations principales du produit</h2>
                    {
                        merchantConfig.enabledLanguages.map((language, idx) =>
                            <div key={language} style={{width: "100%"}}>
                                <div>
                                    <FunctionField
                                        style={{display: 'inline-block'}}
                                        label={""}
                                        source={"content[" + idx + "].language"}
                                        render={
                                            record => {
                                                if (record.content === undefined || record.content.length <= idx || record.content[idx].description === undefined) {
                                                    return "";
                                                }
                                                return <h3>{languages[record.content[idx].language]}</h3>
                                            }
                                        }
                                    />
                                    <CustomTooltip
                                        title={"Traductions"}
                                        body={" Le nom et la description des produits varie en fonction des langues. " +
                                            "Si plusieurs langues sont activées sur votre boutique vous devez saisir les données pour chacune d'elle."}
                                    />
                                </div>
                                <TextInput
                                    style={{display: "none"}}
                                    initialValue={language}
                                    source={"content[" + idx + "].language"}
                                />
                                <TextInput
                                    label={"Nom"}
                                    source={"content[" + idx + "].name"}
                                    validate={[required()]}
                                />
                                <RichTextInput
                                    label={"Description"}
                                    source={"content[" + idx + "].description"}
                                    validate={[required()]}
                                    toolbar={defaultToolBar}
                                />
                            </div>
                        )
                    }
                    <br/>
                    <br/>
                    <TextInput
                        disabled
                        label="Id"
                        source="id"
                        style={{display: "none"}}
                    />
                    <SelectInput
                        label={"Categorie"}
                        source="categoryId"
                        choices={categories}
                        onChange={(val) => setCurrentCategory(val.target.value)}
                        validate={[required()]}
                    />
                    <div style={{width: "100%"}}>
                        <ReferenceInput
                            label="Gamme"
                            resettable={true}
                            source="productRangeId"
                            reference="ranges"
                            filter={{categoryId: currentCategory}}
                        >
                            <SelectInput
                                allowEmpty={true}
                                optionText={"code"}
                            />
                        </ReferenceInput>
                        <CustomTooltip
                            title={"Gamme"}
                            body={"Les produits de la même gamme seront mis en avant sur la page du produit."}
                        />
                    </div>
                    <ReferenceInput label="Tag" source="tagList" reference="tags">
                        <AutocompleteArrayInput
                            allowEmpty={true}
                            optionText="content[0].name"
                        />
                    </ReferenceInput>
                    <div style={{width: "100%"}}>
                        <TextInput
                            label={"Quantité en stock"}
                            source="quantityInStock"
                            resettable={true}
                            validate={[number()]}
                            style={{display: 'inline-block', minWidth: 180, width: 256}}
                        />
                        <CustomTooltip
                            title={"Stock"}
                            body={"Laissez le champ vide pour désactiver la gestion du stock : l'article peut être commandé" +
                                " à l'infini. Sinon mettre le nombre d'articles disponible, le stock sera décrémenté à chaque commande."}
                        />
                    </div>
                    <div style={{width: "100%"}}>
                        <NumberInput
                            label={"Poids (en g)"}
                            source="weight"
                            style={{display: 'inline-block', minWidth: 180, width: 256}}
                            validate={[number(), minValue(0)]}
                        />
                        <CustomTooltip
                            title={"Poids"}
                            body={"Ce champ est utile si vous gérez les frais de port au poid."}
                        />
                    </div>
                    <div style={{width: "100%"}}>
                        <TextInput
                            label={"GTIN"}
                            source="gtin"
                            resettable={true}
                            style={{display: 'inline-block', minWidth: 180, width: 256}}
                        />
                        <CustomTooltip
                            title={"GTIN"}
                            body={"Ce champ est utile pour le référencement et pour les avis clients. Il correspond au" +
                                " code barre de l'article"}
                        />
                    </div>
                    <BooleanInput
                        label={"En ligne"}
                        source="published"
                        options={{
                            icon: <VisibilityOff color={'primary'}/>,
                            checkedIcon: <Visibility/>,
                        }}
                        validate={[required()]}
                        defaultValue={false}
                    />

                </FormTab>
                <FormTab label={"Images"}>
                    <FunctionField
                        label={""}
                        source={"empty"}
                        render={record => {
                            if (!initialLoadFinished && pictures !== undefined && pictures.length === 0 && record.pictures !== undefined) {
                                //first render
                                //Désolé pour le bricolage
                                setTimeout(() => {
                                    setPictures(record.pictures);
                                    setCurrentCategory(record.categoryId);
                                    setInitialLoadFinished(true);
                                }, 500);
                            }
                        }}
                    />
                    {
                        pictures.length > 0 ? <div>
                                <h3 style={{display: 'inline-block'}}>Photos du produit</h3>
                                {/*<CustomTooltip*/}
                                {/*    title={"Photos"}*/}
                                {/*    body={"Glissez et déposer les images pour changer l'ordre d'affichage sur le site. " +*/}
                                {/*    "La première sera l'image principale."}*/}
                                {/*/>*/}
                            </div> :
                            <div style={{width: "100%"}}>
                                <TextInput
                                    style={{display: "none"}}
                                    source={"emptyImage"}
                                    validate={[required()]}
                                />
                                <span>Au minimum une photo est obligatoire</span>
                            </div>
                    }
                    <div style={{width: "100%"}}>
                        {
                            pictures.map((picture, idx) => {
                                return (
                                    <div
                                        style={{marginRight: 5, position: "relative", display: "inline-block"}}
                                        key={idx}
                                    >
                                        <ImageField
                                            style={{display: 'inline-block'}}
                                            path={picture.thumbPath}
                                            name={"Image n°" + idx}
                                            height={200}
                                        />
                                        <span
                                            style={deleteIcon}
                                            onClick={() => {
                                                if (window.confirm("Supprimer cette image ?")) {
                                                    deleteImage(idx);
                                                }
                                            }}
                                        >
                                        <DeleteIcon fontSize={"small"}/>
                                    </span>
                                        <TextInput
                                            style={{display: "none"}}
                                            initialValue={picture.path}
                                            source={"pictures[" + idx + "].path"}
                                        />
                                        <TextInput
                                            style={{display: "none"}}
                                            initialValue={picture.thumbPath}
                                            source={"pictures[" + idx + "].thumbPath"}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <h2 fullWidth>Ajoutez des images ci-dessous</h2>
                    <PictureUpload
                        key={pictures.length}
                        {...props}
                        onNewPictureUpload={addNewPicture}
                    />
                </FormTab>
                <FormTab label={"Prix"} source={"prices"}>
                    {
                        merchantConfig.enabledCurrencies.map((currency, idx) =>
                            <div key={currency}>
                                <div>
                                    <FunctionField
                                        style={{display: 'inline-block'}}
                                        label={""}
                                        source={"prices[" + idx + "].currency"}
                                        render={
                                            record => {
                                                if (record.prices !== undefined) {
                                                    return <h3>{currencies[record.prices[idx].currency]}</h3>
                                                }
                                                return "";
                                            }
                                        }
                                    />
                                    <CustomTooltip
                                        title={"Devises"}
                                        body={"Le prix et la TVA varient en fonction de la devise." +
                                            " Si plusieurs devises sont activées sur votre boutique vous devez saisir les prix pour chacune d'elle."}
                                    />
                                </div>
                                <h5>Prix</h5>
                                <TextInput
                                    style={{display: "none"}}
                                    initialValue={currency}
                                    source={"prices[" + idx + "].currency"}
                                />
                                <SelectInput
                                    label={"Taux TVA"}
                                    source={"prices[" + idx + "].vatRate"}
                                    choices={vatRates}
                                    validate={[required()]}
                                />
                                <br/>
                                <NumberInput
                                    label={"Valeur TTC"}
                                    source={"prices[" + idx + "].amountWithTax"}
                                    validate={[required(), number(), minValue(0)]}
                                />
                                <div>
                                    <h5 style={{display: "inline-block"}}>Prix en promo</h5>
                                    <CustomTooltip
                                        title={"Promotion"}
                                        body={"Si vous saisissez un prix ci-dessous, le prix initial sera barré et le nouveau sera automatiquement appliqué."}
                                    />
                                </div>
                                <br/>
                                <NumberInput
                                    label={"Valeur TTC"}
                                    source={"prices[" + idx + "].discountAmountWithTax"}
                                    validate={[number(), minValue(0)]}
                                />
                            </div>
                        )
                    }
                </FormTab>
                {
                    customFields !== null && customFields !== undefined && customFields.length > 0 &&
                    <FormTab label={"Autre"} source={"custom"}>
                        <h2 fullWidth>Champs personnalisés de votre boutique</h2>
                        {
                            customFields.map(field => getInputField(field))
                        }
                    </FormTab>
                }
            </TabbedForm>
        </Edit>
    )
};

const deleteIcon = {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: "white",
    margin: 5,
    padding: 5,
    borderRadius: 999,
    width: 20,
    height: 20,
};
