import {Constants} from "./Constants";
import {BooleanField, BooleanInput, NumberField, NumberInput, RichTextField, TextField, TextInput} from "react-admin";
import * as React from "react";
import RichTextInput from "ra-input-rich-text";

const axios = require('axios');

export const formatPrice = (price, currency) => {
    if (price === null || price === undefined) {
        return "";
    }
    switch (currency) {
        case "EUR":
            return price.toFixed(2).replace(".", ",") + " €";
        case "USD":
            return price.toFixed(2) + " $";
        case "CHF":
            return price.toFixed(2) + " CHF";
        default:
            return price.toFixed(2);
    }
}

export const currencies = {
    EUR: "Euro - €",
    USD: "Dollar - $",
    CHF: "Franc Suisse - CHF"
};

export const languages = {
    FR: "Français",
    EN: "English"
};

export const countries = {
    FR: "France"
};

export const vatRates = [
    {id: "20", name: "20%"},
    {id: "10", name: "10%"},
    {id: "7.7", name: "7.7%"},
    {id: "5.5", name: "5.5%"},
    {id: "3.7", name: "3.7%"},
    {id: "2.5", name: "2.5%"},
    {id: "2.1", name: "2.1%"},
];

export const getCategories = () => {
    const jwt = localStorage.getItem('auth');
    return axios({
        method: 'get',
        url: Constants.baseApiUrl + Constants.managerPrefix + Constants.categories,
        headers: {'Authorization': 'Bearer ' + jwt}
    });
}

export const getRanges = () => {
    const jwt = localStorage.getItem('auth');
    return axios({
        method: 'get',
        url: Constants.baseApiUrl + Constants.managerPrefix + Constants.ranges,
        headers: {'Authorization': 'Bearer ' + jwt}
    });
}

export const getBody = async (url) => {
    const jwt = localStorage.getItem('auth');
    const req = await axios({
        method: 'get',
        url: url,
        headers: {'Authorization': 'Bearer ' + jwt}
    });
    return req.data;
}

export const getUrlHttp = (url) => {
    axios({
        method: 'get',
        url: url,
    }).then().catch(err => console.log(err));
}

export const getProductUrl = (lang, slug) => {
    const baseShopUrl = localStorage.getItem('baseShopUrl');
    return baseShopUrl + lang.toLowerCase() + "/product/" + slug;
}

export const getConfigUrl = () => {
    return Constants.baseApiUrl + "manage/config";
}

export const formatMondialRelay = (payload) => (
    <div>
        {payload.name} - {payload.relayID}<br/>
        {payload.address1 + " "}
        {payload.address2}<br/>
        {payload.postalCode + " "}
        {payload.locality} <br/>
        {payload.country}
    </div>
)

export const getInputField = (field) => {
    switch (field.type) {
        case "TEXT":
            return <TextInput
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
                resettable={field.resettable}
            />
        case "NUMBER":
            return <NumberInput
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
                resettable={field.resettable}
            />
        case "BOOL":
            return <BooleanInput
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
            />
        case "RICHTEXT":
            return <RichTextInput
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
                resettable={field.resettable}
                toolbar={defaultToolBar}
            />
        default:
            return <></>
    }
}

export const getField = (field) => {
    switch (field.type) {
        case "TEXT":
            return <TextField
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
            />
        case "NUMBER":
            return <NumberField
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
            />
        case "BOOL":
            return <BooleanField
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
            />
        case "RICHTEXT":
            return <RichTextField
                key={field.source}
                label={field.label}
                source={'custom[' + field.source + ']'}
            />
        default:
            return <></>
    }
}

export const scrollToBottom = (delay) => {
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), delay);
}

export const defaultToolBar = [[{'header': [2, 3, 4, false]}], ['bold', 'italic', 'underline'], [{'list': 'ordered'}, {'list': 'bullet'}]];
