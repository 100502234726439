import {
    EditButton,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField, TopToolbar
} from "react-admin";
import {Typography} from "@material-ui/core";
import {ImageField} from "../ImageField";
import {languages} from "../../utils/Utils";
import * as React from "react";
import {useState} from "react";

export const CategoryShow = (props) => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    return (
        <Show {...props} actions={<CategoryShowActions/>} title={"Catégorie"}>
            <SimpleShowLayout>
                <FunctionField
                    label={""}
                    source={"id"}
                    render={record =>
                        <Typography variant={"h4"}>
                            {/* TODO default language */}
                            Catégorie : {record.content.filter(cat => cat.language === "FR")[0].name}
                        </Typography>
                    }
                />
                <FunctionField
                    render={record => <ImageField
                        path={record.coverImage}
                        alt={"Category"}
                        height={100}
                        width={380}
                    />}
                    label={"Couverture"}/>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language}>
                            <FunctionField
                                label={""}
                                source={"content[" + idx + "].language"}
                                render={
                                    record => {
                                        if (record.content === undefined || record.content.length <= idx) {
                                            return "";
                                        }
                                        return <h3>{languages[record.content[idx].language]}</h3>
                                    }
                                }
                            />
                            <TextField
                                style={{display: "none"}}
                                initialvalue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <span style={{fontWeight: 'bold', fontSize: 14}}>Nom : </span>
                            <TextField
                                label={"Nom"}
                                source={"content[" + idx + "].name"}
                            />
                            <br/>
                            <span style={{fontWeight: 'bold', fontSize: 14}}>Sous-titre : </span>
                            <TextField
                                label={"Sous-titre"}
                                source={"content[" + idx + "].subtitle"}
                            />
                            <br/>
                            <br/>
                        </div>
                    )
                }
            </SimpleShowLayout>
        </Show>
    )
};

const CategoryShowActions = (
    {
        basepath, data, resource
    }
) => (
    <TopToolbar>
        <EditButton basepath={basepath} record={data}/>
    </TopToolbar>
);