import * as React from "react";
import {
    Show,
    TopToolbar,
    EditButton,
    SimpleShowLayout,
    TextField,
    FunctionField,
    DateField,
    ArrayField,
    Datagrid, useRefresh, Confirm
} from 'react-admin';
import {Button, Divider, Typography} from "@material-ui/core";
import {Constants} from "../../utils/Constants";
import {countries, formatMondialRelay, scrollToBottom} from "../../utils/Utils";
import {Price, PriceTotal} from "../product/Price";
import {ImageField} from "../ImageField";
import Add from "@material-ui/icons/AddCircleOutline";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import axios from "axios";
import {Loader} from "../Loader";
import {useState} from "react";

export const OrderShow = (props) => {

    const [billGenerating, setBillGenerating] = useState(false);
    const [labelGenerating, setLabelGenerating] = useState(false);
    const [deliveryNoteGenerating, setDeliveryNoteGenerating] = useState(false);
    const [purchaseOrderGenerating, setPurchaseOrderGenerating] = useState(false);
    const [creditGenerating, setCreditGenerating] = useState(false);
    const [creditPopupOpen, setCreditPopupOpen] = useState(false);
    const refresh = useRefresh();

    const generateLabel = (orderId) => {
        const jwt = localStorage.getItem('auth');
        setLabelGenerating(true);
        axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.generateColissimoLabel.replace("%ID%", orderId),
            headers: {'Authorization': 'Bearer ' + jwt}
        }).then(response => {
            setLabelGenerating(false);
            refresh();
        }).catch(error => {
            setLabelGenerating(false);
            alert("Erreur interne " + error);
        });
    }

    const generateBill = (orderId) => {
        const jwt = localStorage.getItem('auth');
        setBillGenerating(true);
        axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.generateBill.replace("%ID%", orderId),
            headers: {'Authorization': 'Bearer ' + jwt}
        }).then(response => {
            setBillGenerating(false);
            refresh();
            scrollToBottom(150);
        }).catch(error => {
            setBillGenerating(false);
            alert("Erreur interne " + error);
        });
    }

    const generateDeliveryNote = (orderId) => {
        const jwt = localStorage.getItem('auth');
        setDeliveryNoteGenerating(true);
        axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.generateDeliveryNote.replace("%ID%", orderId),
            headers: {'Authorization': 'Bearer ' + jwt}
        }).then(response => {
            setDeliveryNoteGenerating(false);
            refresh();
            scrollToBottom(150);
        }).catch(error => {
            setDeliveryNoteGenerating(false);
            alert("Erreur interne " + error);
        });
    }

    const generatePurchaseOrder = (orderId) => {
        const jwt = localStorage.getItem('auth');
        setPurchaseOrderGenerating(true);
        axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.generatePurchaseOrder.replace("%ID%", orderId),
            headers: {'Authorization': 'Bearer ' + jwt}
        }).then(response => {
            setPurchaseOrderGenerating(false);
            refresh();
            scrollToBottom(150);
        }).catch(error => {
            setPurchaseOrderGenerating(false);
            alert("Erreur interne " + error);
        });
    }

    const generateCredit = (orderId) => {
        const jwt = localStorage.getItem('auth');
        setCreditGenerating(true);
        setCreditPopupOpen(false);
        axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.generateCredit.replace("%ID%", orderId),
            headers: {'Authorization': 'Bearer ' + jwt}
        }).then(response => {
            setCreditGenerating(false);
            refresh();
            scrollToBottom(150);
        }).catch(error => {
            setCreditGenerating(false);
            alert("Erreur interne " + error);
        });
    }

    return (
        <Show
            {...props}
            actions={<OrderShowActions/>}
            title={"Commande"}>
            <SimpleShowLayout>
                <FunctionField
                    label={""}
                    source={"id"}
                    render={record =>
                        <Typography variant={"h4"}>
                            Commande n°{record.id} - <span
                            style={Constants.orderStyle[record.status]}>{Constants.orderStatus[record.status]}</span>
                        </Typography>
                    }
                />
                <DateField
                    showTime
                    label={"Date de création"}
                    source="orderDate"
                />
                <FunctionField
                    label={"Email"}
                    source={"email"}
                    render={record => {
                        return <a href={"mailto:" + record.email}>{record.email}</a>;
                    }}
                />
                <FunctionField
                    label={"Téléphone"}
                    source={"phone"}
                    render={record => {
                        return <a href={"tel:" + record.phone}>{record.phone}</a>;
                    }}
                />
                <br/>
                <ArrayField
                    label={"Transactions"}
                    source="transactions"
                >
                    <Datagrid>
                        <TextField
                            label={"ID"}
                            source="transactionId"
                        />
                        <FunctionField
                            label={"Mode de paiement"}
                            source={"record.psp"}
                            render={record => {
                                return Constants.pspName[record.psp]
                            }}
                        />
                        <FunctionField
                            label={"Statut"}
                            source={"record.psp"}
                            render={record => {
                                return Constants.transactionStatus[record.transactionStatus]
                            }}
                        />
                        <DateField
                            label={"Création"}
                            source="creationDate"
                            showTime={true}
                        />
                        <DateField
                            label={"Dernière modification"}
                            source="updateDate"
                            showTime={true}
                        />
                    </Datagrid>
                </ArrayField>
                <Divider/>
                <br/>
                <Typography variant={"h5"}>
                    Livraison
                </Typography>
                <FunctionField
                    label={"Transporteur"}
                    source={""}
                    render={record =>
                        record.delivery.deliveryId !== null ?
                            <>
                                <ImageField
                                    path={record.delivery.image}
                                    name={"Delivery image"}
                                    height={50}
                                />
                                <div>{record.delivery.name}</div>
                            </> : <>Aucun</>
                    }
                />
                <FunctionField
                    label={""}
                    source={"record.colissimoAvailable"}
                    render={record => {
                        if (!record.colissimoAvailable) {
                            return ""
                        }
                        return record.colissimoPath !== null ?
                            <a
                                href={Constants.objectStoragePath + record.colissimoPath}
                                target={"_blank"}
                            >
                                <InsertDriveFileIcon/>
                                <span>Voir l'étiquette</span>
                            </a> :
                            <>
                                {
                                    labelGenerating ? <Loader/> : <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => generateLabel(record.id)}
                                        endIcon={<Add/>}
                                    >
                                        Générer l'étiquette
                                    </Button>
                                }
                            </>
                    }}
                />
                <FunctionField
                    label={""}
                    source={"delivery.parcelNumber"}
                    render={record => {
                        if (record.parcelNumber !== null) {
                            return "N° colis : " + record.parcelNumber
                        } else {
                            return ""
                        }
                    }}
                />
                <FunctionField
                    label={"Point retrait"}
                    source={"delivery.type"}
                    render={record => {
                        if (record.delivery.type === Constants.mondialRelayType) {
                            return formatMondialRelay(record.delivery.deliveryPayload.deliveryMondialRelayPayload);
                        } else {
                            return "Aucun"
                        }
                    }}
                />
                <FunctionField
                    label={"Adresse du client"}
                    source={"deliveryAddress"}
                    render={record =>
                        <div>
                            {record.deliveryAddress.name}<br/>
                            {record.deliveryAddress.companyName} {record.deliveryAddress.companyName !== "" && <br/>}
                            {record.deliveryAddress.streetNumber} {record.deliveryAddress.route}<br/>
                            {record.deliveryAddress.complement} {record.deliveryAddress.complement !== "" && <br/>}
                            {record.deliveryAddress.postalCode} {record.deliveryAddress.locality}<br/>
                            {countries[record.deliveryAddress.country]}
                        </div>
                    }
                />
                <br/>
                <Typography variant={"h5"}>
                    Facturation
                </Typography>
                <FunctionField
                    label={"Adresse"}
                    source={"deliveryAddress"}
                    render={record =>
                        <div>
                            {record.billingAddress.name}<br/>
                            {record.billingAddress.companyName} {record.billingAddress.companyName !== "" && <br/>}
                            {record.billingAddress.streetNumber} {record.billingAddress.route}<br/>
                            {record.billingAddress.postalCode} {record.billingAddress.locality}<br/>
                            {countries[record.billingAddress.country]}
                        </div>
                    }
                />
                <br/>
                <Divider/>
                <br/>
                <ArrayField
                    label={"Panier"}
                    source="cart.products"
                >
                    <Datagrid>
                        <TextField
                            label={"Id produit"}
                            source="productId"
                        />
                        <FunctionField
                            render={
                                record => <img
                                    src={record.image}
                                    alt={record.name}
                                    height={100}
                                    width={100}
                                />
                            }
                            label={"Photo"}
                        />
                        <TextField
                            label={"Nom"}
                            source="name"
                        />
                        <TextField
                            label={"Quantité"}
                            source="quantity"
                        />
                        <FunctionField
                            label={"Prix TTC"}
                            render={record => <Price {...record.price} />}/>
                    </Datagrid>
                </ArrayField>
                <FunctionField
                    label={"Frais de port"}
                    render={record => <PriceTotal {...record.cart.deliveryPrice} currency={record.cart.currency}/>}/>
                <FunctionField
                    label={"Prix total"}
                    render={record => <PriceTotal {...record.cart.totals} currency={record.cart.currency}/>}/>
                <br/>
                <Divider/>
                <br/>
                <Typography variant={"h5"}>
                    Documents
                </Typography>

                <FunctionField
                    label={"Bon de commande"}
                    source={""}
                    render={record =>
                        record.purchaseOrderPath !== null ?
                            <a
                                href={Constants.objectStoragePath + record.purchaseOrderPath}
                                target={"_blank"}
                            >
                                <InsertDriveFileIcon/>
                                <span>Voir le fichier</span>
                            </a> :
                            <>
                                {
                                    purchaseOrderGenerating ? <Loader/> : <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => generatePurchaseOrder(record.id)}
                                        endIcon={<Add/>}
                                    >
                                        Générer
                                    </Button>
                                }
                            </>
                    }
                />
                <FunctionField
                    label={"Bon de livraison"}
                    source={""}
                    render={record =>
                        record.deliveryNotePath !== null ?
                            <a
                                href={Constants.objectStoragePath + record.deliveryNotePath}
                                target={"_blank"}
                            >
                                <InsertDriveFileIcon/>
                                <span>Voir le fichier</span>
                            </a> :
                            <>
                                {
                                    deliveryNoteGenerating ? <Loader/> : <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => generateDeliveryNote(record.id)}
                                        endIcon={<Add/>}
                                    >
                                        Générer
                                    </Button>
                                }
                            </>
                    }
                />
                <FunctionField
                    label={"Facture"}
                    source={"billPath"}
                    render={record =>
                        record.billPath !== null ?
                            <a
                                href={Constants.objectStoragePath + record.billPath}
                                target={"_blank"}
                            >
                                <InsertDriveFileIcon/>
                                <span>Voir le fichier</span>
                            </a> :
                            <>
                                {
                                    billGenerating ? <Loader/> : <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => generateBill(record.id)}
                                        endIcon={<Add/>}
                                    >
                                        Générer
                                    </Button>
                                }
                            </>
                    }
                />
                <FunctionField
                    label={"Avoir"}
                    source={""}
                    render={record =>
                        <>
                            {
                                record.creditPath !== null ?
                                    <a
                                        href={Constants.objectStoragePath + record.creditPath}
                                        target={"_blank"}
                                    >
                                        <InsertDriveFileIcon/>
                                        <span>Voir le fichier</span>
                                    </a> :
                                    <>
                                        {
                                            creditGenerating ? <Loader/> : <Button
                                                size="small"
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => setCreditPopupOpen(true)}
                                                endIcon={<Add/>}
                                            >
                                                Générer
                                            </Button>
                                        }
                                    </>
                            }
                            <Confirm
                                isOpen={creditPopupOpen}
                                title={"Créer un avoir ?"}
                                content={"La création d'un avoir entrainera la génération d'un document PDF ainsi que le passage de la commande " +
                                    "au statut remboursé. Le remboursement n'est pas automatique, vous devrez le faire via votre prestataire de paiement."}
                                onConfirm={() => generateCredit(record.id)}
                                onClose={() => setCreditPopupOpen(false)}
                                confirm={"Confirmer"}
                                cancel={"Annuler"}
                            />
                        </>

                    }
                />

            </SimpleShowLayout>
        </Show>
    )
};


const OrderShowActions = (
    {
        basepath, data, resource
    }
) => (
    <TopToolbar>
        <EditButton basepath={basepath} record={data}/>
    </TopToolbar>
);
