import React from 'react';
import './App.css';
import {Admin, Resource} from "react-admin";
import {ProductList} from "./components/product/ProductList";
import {authProvider} from "./providers/authProvider";
import LoginPage from "./pages/Login";
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CategoryIcon from '@material-ui/icons/Category';
import RangeIcon from '@material-ui/icons/GroupWork';
import LabelIcon from '@material-ui/icons/LocalOffer';
import RateReview from '@material-ui/icons/RateReview';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import {dataProvider} from "./providers/dataProvider";
import {ProductShow} from "./components/product/ProductShow";
import {ProductCreate} from "./components/product/ProductCreate";
import {ProductEdit} from "./components/product/ProductEdit";
import {OrderList} from "./components/order/OrderList";
import {OrderShow} from "./components/order/OrderShow";
import {OrderEdit} from "./components/order/OrderEdit";
import Dashboard from "./components/Dashboard";
import {theme} from "./utils/theme";
import {CategoryList} from "./components/category/CategoryList";
import {CategoryShow} from "./components/category/CategoryShow";
import {CategoryEdit} from "./components/category/CategoryEdit";
import {CategoryCreate} from "./components/category/CategoryCreate";
import {RangeList} from "./components/range/RangeList";
import {RangeShow} from "./components/range/RangeShow";
import {RangeEdit} from "./components/range/RangeEdit";
import {RangeCreate} from "./components/range/RangeCreate";
import {TagCreate} from "./components/tag/TagCreate";
import {TagList} from "./components/tag/TagList";
import {TagEdit} from "./components/tag/TagEdit";
import {TagShow} from "./components/tag/TagShow";
import {ReviewShow} from "./components/review/ReviewShow";
import {ReviewList} from "./components/review/ReviewList";
import {ReviewEdit} from "./components/review/ReviewEdit";
import {OtherShow} from "./components/other/OtherShow";
import {OtherList} from "./components/other/OtherList";
import {OtherEdit} from "./components/other/OtherEdit";

const messages = {
    'fr': frenchMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'fr');

function App() {
    return (
        <Admin
            loginPage={LoginPage}
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            dataProvider={dataProvider}
            // dashboard={Dashboard}
            theme={theme}
            disableTelemetry
        >
            <Resource
                name="products"
                options={{label: 'Produits'}}
                icon={ShoppingCartIcon}
                show={ProductShow}
                list={ProductList}
                create={ProductCreate}
                edit={ProductEdit}
            />
            <Resource
                name="ranges"
                options={{label: 'Gammes'}}
                icon={RangeIcon}
                show={RangeShow}
                list={RangeList}
                create={RangeCreate}
                edit={RangeEdit}
            />
            <Resource
                name="categories"
                options={{label: 'Categories'}}
                icon={CategoryIcon}
                show={CategoryShow}
                list={CategoryList}
                create={CategoryCreate}
                edit={CategoryEdit}
            />
            <Resource
                name="tags"
                options={{label: 'Tags'}}
                icon={LabelIcon}
                show={TagShow}
                list={TagList}
                create={TagCreate}
                edit={TagEdit}
            />
            <Resource
                name="reviews"
                options={{label: 'Avis'}}
                icon={RateReview}
                show={ReviewShow}
                list={ReviewList}
                edit={ReviewEdit}
            />
            <Resource
                name="orders"
                options={{label: 'Commandes'}}
                icon={ReceiptIcon}
                show={OrderShow}
                list={OrderList}
                edit={OrderEdit}
            />
            <Resource
                name="other"
                options={{label: 'Autre'}}
                icon={MoreHoriz}
                show={OtherShow}
                list={OtherList}
                edit={OtherEdit}
            />
        </Admin>
    );
}

export default App;
