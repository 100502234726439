import {BooleanField, Datagrid, EditButton, FunctionField, List, ShowButton} from "react-admin";
import * as React from "react";

export const TagList = (props) => {

        return (
            <List
                {...props}
                title={"Liste des tags"}
                // filters={<OrderFilter/>}
                exporter={false}
                bulkActionButtons={false}
                sort={{field: 'id', order: 'DESC'}}
            >
                <Datagrid>
                    <FunctionField
                        source="name"
                        label={"Nom"}
                        sortable={false}
                        // TODO use default language
                        render={record => record.content.filter(tag => tag.language === "FR")[0].name}
                    />
                    <BooleanField
                        label={"Public"}
                        source="publicTag"
                    />
                    <ShowButton basepath="/tags" record={props.record}/>
                    <EditButton basepath="/tags"/>
                </Datagrid>
            </List>
        )
    }
;
