import * as React from "react";
import {
    Edit,
    SimpleForm,
    Toolbar,
    SaveButton,
    TextInput, TextField, DateField, NumberField, DeleteButton, required,
} from 'react-admin';

export const ReviewEdit = (props) => {

    const transform = (values) => {
        return {
            response: values.response
        };
    }

    return (
        <Edit {...props} transform={transform}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>}>
                <h2>Répondre à l'avis</h2>
                <TextField
                    source="productName"
                    label={"Produit"}
                />
                <TextField
                    source="orderId"
                    label={"N° Commande"}
                />
                <TextField
                    source="author"
                    label={"Auteur"}
                />
                <DateField
                    showTime
                    label={"Date"}
                    source={"reviewDate"}
                />
                <NumberField
                    label={"Score"}
                    source="score"
                />
                <TextField
                    source="review"
                    label={"Avis client"}
                />
                <TextInput
                    style={{width: "100%"}}
                    source="response"
                    label={"Réponse"}
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    )

}
