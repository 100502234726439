import * as React from "react";
import {useEffect, useRef, useState} from "react";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import {Constants} from "../utils/Constants";
import {Button, LinearProgress} from "@material-ui/core";
import Add from '@material-ui/icons/AddCircleOutline';
import Backspace from '@material-ui/icons/Backspace';

const axios = require('axios');

export const ImageCrop = (props) => {

    const config = {
        product: {
            crop: {aspect: 1, width: 450},
            endpoint: Constants.uploadProductImage
        },
        category: {
            crop: {aspect: 1000 / 263, width: 450},
            endpoint: Constants.uploadCategoryImage
        },
        range: {
            crop: {aspect: 1, width: 450},
            endpoint: Constants.uploadRangeImage
        }
    };

    const [src, setSrc] = useState();
    const [crop, setCrop] = useState(config[props.type].crop);
    const [loading, setLoading] = useState(false);
    const cropper = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);

    useEffect(() => {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            setSrc(reader.result)
        );
        reader.readAsDataURL(props.file);
    }, []);

    const validateCrop = () => {
        setLoading(true);

        const image = cropper.current.imageRef;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const crop = {
            beginX: Math.round(completedCrop.x * scaleX),
            beginY: Math.round(completedCrop.y * scaleY),
            width: Math.round(completedCrop.width * scaleX),
            height: Math.round(completedCrop.height * scaleY)
        };

        const bodyFormData = new FormData();
        bodyFormData.append('file', props.file);
        bodyFormData.append('crop', JSON.stringify(crop));

        const jwt = localStorage.getItem('auth');
        axios({
            method: 'post',
            url: Constants.baseApiUrl + Constants.managerPrefix + config[props.type].endpoint,
            data: bodyFormData,
            headers: {'Authorization': 'Bearer ' + jwt, 'Content-Type': 'multipart/form-data'}
        }).then(response => {
            console.log("upload ok", response.data);
            setLoading(false);
            props.onConfirm(response.data);
        }).catch(error => {
            alert(error);
            setLoading(false);
        });
    }

    return (
        <div style={{width: "50%"}}>
            {
                loading ? (
                    <>
                        <br/>
                        <span>L'image est en cours d'envoi</span>
                        <br/>
                        <LinearProgress style={{width: "50%"}}/>
                    </>
                ) : (
                    <>
                        <ReactCrop
                            ref={cropper}
                            src={src}
                            crop={crop}
                            onChange={newCrop => setCrop(newCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                        />
                        {
                            props.cancel ? <Button
                                variant="outlined"
                                color="secondary"
                                onClick={props.cancel}
                                startIcon={<Backspace/>}
                                style={{marginRight: 20}}
                            >
                                Annuler
                            </Button> : null
                        }
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={validateCrop}
                            endIcon={<Add/>}
                        >
                            Valider le rognage
                        </Button>
                    </>
                )
            }
        </div>
    );
};