import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import {useState} from "react";

export const OtherShow = (props) => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    return (
        <Show
            {...props}
            title={"Autre"}>
            <SimpleShowLayout>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language}>
                            <TextField
                                style={{display: "none"}}
                                initialvalue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <span style={{fontWeight: 'bold', fontSize: 14}}>Bandeau {language} : </span>
                            <TextField
                                label={"Texte"}
                                source={"content[" + idx + "].value"}
                            />
                        </div>
                    )
                }

            </SimpleShowLayout>
        </Show>
    )
};
