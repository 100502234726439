import {useRef, useState} from "react";
import {
    Create,
    required,
    SimpleForm,
    TextInput, Toolbar
} from "react-admin";
import {languages} from "../../utils/Utils";
import {CustomTooltip} from "../CustomTooltip";
import * as React from "react";
import {PictureUpload} from "../PictureUpload";
import {ImageField} from "../ImageField";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

export const CategoryCreate = props => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));
    const [picture, setPicture] = useState(null);
    const stateRef = useRef();
    stateRef.current = picture;

    const transform = (values) => {
        const obj = {...values};
        obj.coverImage = stateRef.current;
        return obj;
    }

    const uploadPictureCallback = (obj) => {
        setPicture(obj);
    }

    const deleteImage = () => {
        setPicture(null);
    }

    return (
        <Create title="Ajout d'une catégorie"  {...props} transform={transform}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={"show"}>
                <h2 fullWidth>Création d'une catégorie</h2>
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language} style={{width: "100%"}}>
                            <div>
                                <h3 style={{display: 'inline-block'}}>{languages[language]}</h3>
                                <CustomTooltip
                                    title={"Traductions"}
                                    body={"Le nom et le sous-titre des catégorie varient en fonction des langues. " +
                                    "Si plusieurs langues sont activées sur votre boutique vous devez saisir les données pour chacune d'elle."}
                                />
                            </div>
                            <TextInput
                                style={{display: "none"}}
                                initialValue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <TextInput
                                label={"Nom"}
                                source={"content[" + idx + "].name"}
                                validate={[required()]}
                            />
                            <br/>
                            <TextInput
                                fullWidth
                                label={"Sous-titre"}
                                source={"content[" + idx + "].subtitle"}
                                validate={[required()]}
                            />
                        </div>
                    )
                }
                {
                    picture === null ? (
                        <>
                            <h2 fullWidth>Ajoutez l'image</h2>
                            <PictureUpload
                                type={"category"}
                                {...props}
                                onNewPictureUpload={uploadPictureCallback}
                            />
                            <TextInput
                                style={{display: "none"}}
                                source={"emptyImage"}
                                validate={[required()]}
                            />
                        </>
                    ) : (
                        <div style={{position: 'relative'}}>
                            <h2 fullWidth>Image</h2>
                            <div style={{position: 'relative'}}>

                                <ImageField
                                    path={picture}
                                    alt={"Category image"}
                                    height={100}
                                    width={380}
                                />
                                <span
                                    style={deleteIcon}
                                    onClick={() => {
                                        if (window.confirm("Supprimer l'image ?")) {
                                            deleteImage();
                                        }
                                    }}
                                >
                                <DeleteIcon fontSize={"small"}/>
                            </span>
                            </div>
                        </div>
                    )
                }
            </SimpleForm>
        </Create>

    )
}

const deleteIcon = {
    position: "absolute",
    top: 0,
    left: 340,
    zIndex: 10,
    backgroundColor: "white",
    margin: 5,
    padding: 5,
    borderRadius: 999,
    width: 20,
    height: 20,
};