import {defaultTheme} from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";

export const theme = createMuiTheme(
    merge({}, defaultTheme, {
        palette: {
            primary: {
                light: "#f5f2e9",
                main: "#e33d63",
            },
            secondary: {
                light: "#f5f2e9",
                main: "#e33d63",
            },
        }
    })
);