import {
    Datagrid, DateField,
    EditButton,
    FunctionField,
    List, NumberField,
    ShowButton,
    SimpleList,
    TextField
} from "react-admin";
import * as React from "react";
import {useMediaQuery} from "@material-ui/core";

export const ReviewList = (props) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List
            {...props}
            title="Liste des avis"
            exporter={false}
            bulkActionButtons={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <DateField
                        label={"Date"}
                        source={"reviewDate"}
                        record={record}
                    />}
                    secondaryText={record => record.score + "/5 - " + record.review}
                    tertiaryText={record => record.productName}
                    linkType={"show"}
                />
            ) : (
                <Datagrid>
                    <TextField source="id"/>

                    <TextField
                        source="productName"
                        label={"Produit"}
                    />
                    <TextField
                        source="orderId"
                        label={"N° Commande"}
                    />
                    <DateField
                        showTime
                        label={"Date"}
                        source={"reviewDate"}
                    />
                    <NumberField
                        label={"Score"}
                        source="score"
                    />
                    <TextField
                        source="review"
                        label={"Avis"}
                    />
                    <FunctionField
                        render={record => record.response === null ? "Non" : "Oui"}
                        label={"Réponse"}
                    />
                    <ShowButton basepath="/reviews" record={props.record}/>
                    <EditButton basepath="/reviews"/>
                </Datagrid>
            )}
        </List>
    )

}
