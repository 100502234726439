import {fetchUtils} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import {Constants} from "../utils/Constants";
import {getBody, getConfigUrl, getProductUrl, getUrlHttp} from "../utils/Utils";

const axios = require('axios');

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const jwt = localStorage.getItem('auth');
    options.headers.set('Authorization', 'Bearer ' + jwt);
    return fetchUtils.fetchJson(url, options);
};

const restDataProvider = simpleRestProvider(Constants.baseApiUrl + Constants.managerPrefix, httpClient);

const getConfigOther = async () => {
    const body = {
        'data': [{
            id: 'top-header',
            name: 'Top header',
            description: 'Texte du bandeau en haut du site',
            content: (await getBody(getConfigUrl()))['topHeader']
        }],
        'total': 1
    };
    return body;
}

export const dataProvider = {
    ...restDataProvider,
    getOne: async (resource, params) => {
        if (resource === "other") {
            const body = await getConfigOther();
            return Promise.resolve({'data': body['data'][0]});
        }
        return restDataProvider.getOne(resource, params);
    },
    getList: async (resource, params) => {
        if (resource === "other") {
            return Promise.resolve(await getConfigOther());
        }
        return restDataProvider.getList(resource, params);
    },
    update: async (resource, params) => {
        if (resource === 'products') {
            const response = await restDataProvider.update(resource, params);

            if (response.data?.published) {
                response.data?.content.forEach(data => {
                    const url = getProductUrl(data.language, data.slug);
                    getUrlHttp(url);
                })
            }
            return response;
        }
        if (resource === 'other') {
            const copyParams = {...params};
            copyParams.id = "ignored";
            copyParams.data = {
                topHeader: params.data.content
            };
            const res = await restDataProvider.update('config', copyParams);
            return Promise.resolve({'data': {...res.data.topHeader, id: 'top-header'}});
        }

        return restDataProvider.update(resource, params);
    },
    create: async (resource, params) => {

        if (resource !== 'products' || !params.data.pictures) {
            return restDataProvider.create(resource, params);
        }

        const cloneData = {...params.data};
        const response = await restDataProvider.create(resource, {
            ...params,
            data: cloneData
        });

        if (response.data?.published) {
            response.data?.content.forEach(data => {
                const url = getProductUrl(data.language, data.slug);
                getUrlHttp(url);
            })
        }

        return response;
    },
};
