import {Constants} from "../utils/Constants";
import decodeJwt from 'jwt-decode';

export const authProvider = {
    login: ({username, password}) => {
        const request = new Request(Constants.baseApiUrl + Constants.managerPrefix + '/login', {
            method: 'POST',
            body: JSON.stringify({email: username, password: password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                const decodedToken = decodeJwt(auth.jwt);
                localStorage.setItem('merchantConfig', JSON.stringify(auth.merchantConfig));
                localStorage.setItem('baseShopUrl', auth.baseShopUrl);
                localStorage.setItem('auth', auth.jwt);
                localStorage.setItem('customProductFields', JSON.stringify(auth.customProductFields));
                localStorage.setItem('name', decodedToken.firstName + " " + decodedToken.lastName);
                localStorage.setItem('permissions', JSON.stringify(decodedToken.roles));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject(),
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('permissions');
        localStorage.removeItem('customProductFields');
        localStorage.removeItem('name');
        localStorage.removeItem('merchantConfig');
        return Promise.resolve();
    },
    getIdentity: () => {
        const name = localStorage.getItem('name');
        return Promise.resolve({fullName: name})
    },
    getPermissions: params => {
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        return Promise.resolve(permissions);
    },
};
