import * as React from "react";
import {
    Edit,
    SimpleForm,
    SelectInput,
    Toolbar,
    SaveButton,
    FunctionField,
    TextInput,
} from 'react-admin';
import {CustomTooltip} from "../CustomTooltip";
import {useState} from "react";
import {Constants} from "../../utils/Constants";

const OrderEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const OrderEdit = props => {

    const [statusList, setStatusList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");

    const transform = (values) => {
        if (values.parcelNumber) {
            let parcelNumber = values.parcelNumber + "";
            parcelNumber = parcelNumber.replaceAll(" ", "");
            return {
                orderStatus: values.status,
                parcelNumber: parcelNumber
            };
        }
        return {
            orderStatus: values.status,
        };
    }

    return (
        <Edit {...props} transform={transform}>
            <SimpleForm toolbar={<OrderEditToolbar/>}>
                <div style={{width: "100%"}}>
                    <h4 style={{display: "inline-block"}}>Mettre à jour l'état de la commande</h4>
                    <CustomTooltip
                        title={"Email"}
                        body={"Attention, le changement de statut entraîne un envoi d'email au client."}
                    />
                </div>
                <FunctionField
                    label={""}
                    source={"empty"}
                    render={record => {
                        if (statusList.length === 0) {
                            if (record.delivery !== null && record.delivery.type === "CLICKANDCOLLECT") {
                                setStatusList(Constants.orderAvailableStatus["CLICKANDCOLLECT"].map(status => {
                                    return {
                                        id: status,
                                        name: Constants.orderStatus[status]
                                    }
                                }));
                            } else if (record.delivery !== null && record.delivery.type !== null) {
                                setStatusList(Constants.orderAvailableStatus["OTHERS"].map(status => {
                                    return {
                                        id: status,
                                        name: Constants.orderStatus[status]
                                    }
                                }));
                            } else {
                                setStatusList([{
                                    id: record.status,
                                    name: Constants.orderStatus[record.status]
                                }]);
                            }
                        }
                    }}
                />
                <SelectInput
                    label={"Statut de la commande"}
                    source="status"
                    choices={statusList}
                    onChange={e => setSelectedStatus(e.target.value)}
                />
                {
                    selectedStatus === "SENDED" && <TextInput
                        label={"Numéro de colis"}
                        source="parcelNumber"
                        resettable={true}
                    />
                }
            </SimpleForm>
        </Edit>
    )
}
