import React from 'react';
import {Login} from 'react-admin';
import {withStyles} from '@material-ui/core/styles';

const styles = ({
    main: {
        background: 'url(/background.png)',
        backgroundSize: 'cover'
    },
    avatar: {
        background: 'url(/logo.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: 80,
    },
    icon: {display: 'none'},
});

const LoginPage = props => (
    <Login
        {...props}
    />
);

export default withStyles(styles)(LoginPage);
