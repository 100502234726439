import {useState} from "react";
import {BooleanInput, Create, required, SimpleForm, TextInput, Toolbar} from "react-admin";
import {languages} from "../../utils/Utils";
import {CustomTooltip} from "../CustomTooltip";
import * as React from "react";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

export const TagCreate = props => {

    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    return (
        <Create title="Ajout d'un tag"  {...props}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={"show"}>
                <h2 fullWidth>Création d'un tag</h2>
                <BooleanInput
                    label={"Tag public"}
                    source="publicTag"
                    options={{
                        icon: <VisibilityOff color={'primary'}/>,
                        checkedIcon: <Visibility/>,
                    }}
                    validate={[required()]}
                    defaultValue={false}
                />
                {
                    merchantConfig.enabledLanguages.map((language, idx) =>
                        <div key={language} style={{width: "100%"}}>
                            <div>
                                <h3 style={{display: 'inline-block'}}>{languages[language]}</h3>
                                <CustomTooltip
                                    title={"Traductions"}
                                    body={"Le nom du tag varie en fonction des langues. " +
                                        "Si plusieurs langues sont activées sur votre boutique vous devez saisir les données pour chacune d'elle."}
                                />
                            </div>
                            <TextInput
                                style={{display: "none"}}
                                initialValue={language}
                                source={"content[" + idx + "].language"}
                            />
                            <TextInput
                                label={"Nom"}
                                source={"content[" + idx + "].name"}
                                validate={[required()]}
                            />
                        </div>
                    )
                }
            </SimpleForm>
        </Create>

    )
}

