import * as React from "react";
import {Constants} from "../utils/Constants";

export const ImageField = (props) => (
    <img
        src={Constants.objectStoragePath + props.path}
        alt={props.name}
        height={props.height}
        width={props.width ? props.width : props.height}
    />
);