import * as React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    NumberField,
    SelectField,
    TopToolbar,
    EditButton,
    BooleanField,
    FunctionField, CloneButton, ReferenceField, ReferenceManyField, SingleFieldList, ChipField
} from 'react-admin';
import {ImageField} from "../ImageField";
import {
    currencies,
    getCategories,
    getField,
    getProductUrl,
    languages
} from "../../utils/Utils";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {useEffect, useState} from "react";
import {PriceBO} from "./Price";
import {Button} from "@material-ui/core";
import OpenInNew from "@material-ui/icons/OpenInNew";

export const ProductShow = (props) => {

    const [categories, setCategories] = useState([]);
    const [customFields] = useState(JSON.parse(localStorage.getItem('customProductFields')));
    const [merchantConfig] = useState(JSON.parse(localStorage.getItem('merchantConfig')));

    useEffect(() => {
        getCategories()
            .then(response => {
                setCategories(response.data.map(cat => {
                    return {id: cat.id, name: cat.name}
                }));
            })
            .catch(err => {
                //TODO toast
                alert("erreur inattendue");
            })
    }, []);

    return (
        <Show {...props} actions={<ProductShowActions/>}>
            <TabbedShowLayout>
                <Tab label={"Principal"}>
                    <h2>Informations principales du produit</h2>
                    {
                        merchantConfig.enabledLanguages.map((language, idx) =>
                            <div key={language}>
                                <FunctionField
                                    label={""}
                                    source={"content[" + idx + "].language"}
                                    render={
                                        record => {
                                            if (record.content === undefined || record.content.length <= idx || record.content[idx].description === undefined) {
                                                return "";
                                            }
                                            return <h3>{languages[record.content[idx].language]}</h3>
                                        }
                                    }
                                />
                                <TextField
                                    style={{display: "none"}}
                                    initialvalue={language}
                                    source={"content[" + idx + "].language"}
                                />
                                <span style={{fontWeight: 'bold', fontSize: 14}}>Nom : </span>
                                <TextField
                                    label={"Nom"}
                                    source={"content[" + idx + "].name"}
                                />
                                <br/>
                                <FunctionField
                                    label={"Description"}
                                    source={"content[" + idx + "].description"}
                                    render={
                                        record => {
                                            if (record.content === undefined || record.content.length <= idx || record.content[idx].description === undefined) {
                                                return "";
                                            }
                                            return (
                                                <div>
                                                    <br/>
                                                    <span style={{fontWeight: 'bold'}}>Description :</span>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: record.content[idx].description}}
                                                    />
                                                </div>
                                            )
                                        }
                                    }
                                />
                                <FunctionField
                                    source={"openInNew[" + idx + "]"}
                                    render={
                                        record => {
                                            if (record.content === undefined || record.content.length <= idx || record.content[idx].slug === undefined) {
                                                return "";
                                            }
                                            const url = getProductUrl(record.content[idx].language, record.content[idx].slug);
                                            return (
                                                <Button
                                                    size={"small"}
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={() => {
                                                        if (record.published) {
                                                            window.open(url, '_blank').focus();
                                                        } else {
                                                            alert("Vous devez publier le produit pour qu'il soit visible");
                                                        }
                                                    }}
                                                    endIcon={<OpenInNew/>}
                                                    style={{marginTop: 10}}
                                                >
                                                    Voir sur la boutique
                                                </Button>
                                            )
                                        }
                                    }
                                />

                            </div>
                        )
                    }
                    <br/>
                    <SelectField
                        label={"Categorie"}
                        source="categoryId"
                        choices={categories}
                    />
                    <ReferenceField
                        label="Gamme"
                        resettable={true}
                        source="productRangeId"
                        reference="ranges"
                        link={false}
                    >
                        <TextField source="code"/>
                    </ReferenceField>
                    <ReferenceManyField
                        label="Tags"
                        source="tagList"
                        reference="tags"
                        target={"tag_id"}
                    >
                        <SingleFieldList>
                            <ChipField source="content[0].name"/>
                        </SingleFieldList>
                    </ReferenceManyField>
                    <TextField
                        label={"Quantité en stock"}
                        source="quantityInStock"
                        resettable={"true"}
                    />

                    <NumberField
                        label={"Poids (en g)"}
                        source="weight"
                    />
                    <TextField
                        label={"GTIN"}
                        source="gtin"
                    />
                    <BooleanField
                        label={"En ligne"}
                        source="published"
                        options={{
                            icon: <VisibilityOff color={'primary'}/>,
                            checkedIcon: <Visibility/>,
                        }}
                    />

                </Tab>
                <Tab label={"Images"}>

                    <FunctionField
                        label={""}
                        source={"pictures"}
                        render={
                            record => {
                                const pictures = record.pictures;

                                return (
                                    <div style={{width: "100%"}}>
                                        <h2>Photos du produit</h2>
                                        {
                                            pictures.map((picture, idx) => {
                                                return (<span style={{marginRight: 5}} key={idx}>
                                                    <ImageField
                                                        style={{display: 'inline-block'}}
                                                        path={picture.thumbPath}
                                                        name={"Image n°" + idx}
                                                        height={200}
                                                    />
                                                    <TextField
                                                        style={{display: "none"}}
                                                        initialvalue={picture.path}
                                                        source={"pictures[" + idx + "].path"}
                                                    />
                                                    <TextField
                                                        style={{display: "none"}}
                                                        initialvalue={picture.thumbPath}
                                                        source={"pictures[" + idx + "].thumbPath"}
                                                    />
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }}
                    />
                </Tab>
                <Tab label={"Prix"} source={"prices"}>
                    {
                        merchantConfig.enabledCurrencies.map((currency, idx) =>
                            <div key={currency}>
                                <FunctionField
                                    label={""}
                                    source={"prices[" + idx + "].currency"}
                                    render={
                                        record => {
                                            if (record.prices !== undefined) {
                                                return <h3>{currencies[record.prices[idx].currency]}</h3>
                                            }
                                            return "";
                                        }
                                    }
                                />
                                <TextField
                                    style={{display: "none"}}
                                    initialvalue={currency}
                                    source={"prices[" + idx + "].currency"}
                                />
                                <FunctionField
                                    label={""}
                                    source={"prices[" + idx + "].amountWithTax"}
                                    render={
                                        record => {
                                            if (record.prices !== undefined) {
                                                return <PriceBO {...record.prices[idx]}/>
                                            }
                                            return "";
                                        }
                                    }
                                />
                            </div>
                        )
                    }
                </Tab>
                {
                    customFields !== null && customFields !== undefined && customFields.length > 0 &&
                    <Tab label={"Autre"} source={"custom"}>
                        {
                            customFields.map(field => getField(field))
                        }
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    )
};

const ProductShowActions = (
    {
        basepath, data, resource
    }
) => (
    <TopToolbar>
        <EditButton basepath={basepath} record={data}/>
        <CloneButton basepath={basepath} record={data}/>
    </TopToolbar>
);
